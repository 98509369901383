<template>
  <div class="row bg-white">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <div class="row">
        <div v-show="!isFirstGraph" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <apexchart 
            ref="riskChart" 
            height="400" 
            :width="riskChart.chartWidth"
            :options="riskChart.chartOptions" 
            :series="riskChart.series">
          </apexchart>
        </div>
        <div v-show="!isSecondGraph" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <apexchart 
            ref="riskStandardChart" 
            height="400" 
            :width="riskStandardChart.chartWidth"
            :options="riskStandardChart.chartOptions" 
            :series="riskStandardChart.series">
          </apexchart>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <apexchart 
            ref="processImprChart" 
            height="300" 
            type="bar"
            :width="processImprChart.chartWidth"
            :options="processImprChart.chartOptions" 
            :series="processImprChart.series">
          </apexchart>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <apexchart 
            ref="processRiskRegisterChart" 
            height="300" 
            type="bar"
            :width="processRiskRegisterChart.chartWidth"
            :options="processRiskRegisterChart.chartOptions" 
            :series="processRiskRegisterChart.series">
          </apexchart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import selectConfig from '@/js/selectConfig';
export default {
  name: 'fmStatus',
  components: {
    apexchart: VueApexCharts
  },
  props: {
    param: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',
        ramStepCd: '',
        vendorFlag: false,
        ramTechniqueCd: '',
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      status: {
        riskChart: [],
        riskStandardChart: [],
        processImprChart: [],
        processRiskRegisterChart: [],
      },
      riskChart: {
        chartOptions: {
          title: {
            text: '위험도별 발굴 현황(%)'
          },
          chart: {
            type: 'pie',
          },
          colors: [],
          labels: [],
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }],
          legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 150,
            offsetY: -30,
          },
          fill: {
            // type: 'gradient',
          },
        },
        series: [
          {
            data: [],
          },
        ],
        chartWidth: '80%',
      },
      riskStandardChart: {
        chartOptions: {
          title: {
            text: '위험도 기준별 발굴 건 수'
          },
          chart: {
            type: 'polarArea',
          },
          colors: [],
          labels: [],
          legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 150,
            offsetY: -30,
          },
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }],
          fill: {
            // type: 'gradient',
          },
          yaxis: [
            {
              labels: {
                formatter: function(val) {
                  return Number.isInteger(val) ? val.toFixed(0) : val.toFixed(1);
                }
              }
            }
          ],
        },
        series: [
          {
            data: [],
          },
        ],
        chartWidth: '80%',
      },
      processImprChart: {
        chartOptions: {
          title: {
            text: '공정별 개선대책 수립 및 실시 건 수'
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '45%',
              endingShape: 'rounded'
            },
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          xaxis: {
            categories: [],
            labels: {
              style: {
                fontSize: '12px'
              },
              formatter: function (val) {
                return "[공정] " + val
              }
            }
          },
          yaxis: {
            title: {
              text: '개선 건 수',
              style: {
                fontSize: '14px',
                fontWeight: 600,
              },
            },
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val + " 건"
              }
            }
          },
          fill: {
            opacity: 1,
          },
        },
        series: [
          {
            name: '진행 건 수',
            data: [],
          },
          {
            name: '지연 건 수',
            data: [],
          },
          {
            name: '완료 건 수',
            data: [],
          },
        ],
        chartWidth: '80%',
      },
      processRiskRegisterChart: {
        chartOptions: {
          title: {
            text: ''
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '45%',
              endingShape: 'rounded'
            },
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          xaxis: {
            categories: [],
            labels: {
              style: {
                fontSize: '12px'
              },
              formatter: function (val) {
                return "[공정] " + val
              }
            }
          },
          yaxis: {
            title: {
              // text: '위험등록부 건 수',
              style: {
                fontSize: '14px',
                fontWeight: 600,
              },
            },
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val + " 건"
              }
            }
          },
          fill: {
            opacity: 1,
          },
        },
        series: [
        ],
        chartWidth: '80%',
      },
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    isFirstGraph() {
      return this.param.ramTechniqueCd === 'RT00000005'
    },
    isSecondGraph() {
      return this.param.ramTechniqueCd === 'RT00000005' 
      // || this.param.ramTechniqueCd === 'RT00000010' || this.param.ramTechniqueCd === 'RT00000035'
    },
    listUrl() {
      let url = null;
      if (this.param.ramTechniqueCd === 'RT00000001') {
        // HAZOP (o)
        url = selectConfig.ram.hazop.status.url;
      } else if (this.param.ramTechniqueCd === 'RT00000005') {
        // K-PSR (o)
        url = selectConfig.ram.kpsr.status.url;
      } else if (this.param.ramTechniqueCd === 'RT00000010') {
        // Check-list (o)
        url = selectConfig.ram.checklist.status.url;
      } else if (this.param.ramTechniqueCd === 'RT00000015') {
        // JRA (o)
        url = selectConfig.ram.jsa.status.url;
      } else if (this.param.ramTechniqueCd === 'RT00000020') {
        // KRAS (o)
        url = selectConfig.ram.kras.status.url;
      } else if (this.param.ramTechniqueCd === 'RT00000025') {
        // 4M (o)
        url = selectConfig.ram.fm.status.url;
      } else if (this.param.ramTechniqueCd === 'RT00000030') {
        // CHARM (o)
        url = selectConfig.ram.charm.status.url;
      } else if (this.param.ramTechniqueCd === 'RT00000035') {
        // 3단계 판단법 (o)
        url = selectConfig.ram.third.status.url;
      }
      return url;
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        ramRiskAssessmentPlanId: this.param.ramRiskAssessmentPlanId,
        vendorFlag: this.param.vendorFlag ? 1 : 0
      }
      this.$http.request((_result) => {
        this.$_.extend(this.status, _result.data);

        if (!this.isFirstGraph) {
          this.setRiskChart();
        }
        if (!this.isSecondGraph) {
          this.setRiskStandardChart();
        }
        this.setProcessImprChart();
        if (!this.param.vendorFlag) {
          this.setProcessRiskRegisterChart();
        }
      },);
    },
    setRiskChart() {
      // 위험도별 발굴
      // risk 	cnt color
      // 1		  2	#b0b0b0
      // 2		  4	#b0b0b0
      // 3		  4	#b0b0b0
      // 4		  9	#ababab
      // 5		  1	#ababab
      // 6		  6	#ababab
      // 8		  0	#ababab
      // 9		  3	#ff8282
      // 10		  1	#ff8282
      // 12		  0	#ff8282
      // 15		  0	#ff7a7a
      // 16		  0	#9c0000
      // 20		  0	#9c0000
      this.$set(this.riskChart.chartOptions, 'colors', this.$_.map(this.status.riskChart, 'color'))
      this.$set(this.riskChart.chartOptions, 'labels', this.$_.map(this.status.riskChart, 'risk'))
      this.$set(this.riskChart, 'series', this.$_.map(this.status.riskChart, 'cnt'))
      this.riskChart.chartWidth = '90%';
      this.$refs['riskChart'].refresh();
    },
    setRiskStandardChart() {
      // 위험도별 발굴
      // riskContents 	          cnt   color
      // 무시할 수 있는 위험(0 ~ 3)	10	  #b0b0b0
      // 미미한 위험(4 ~ 6)	        16	  #ababab
      // 경미한 위험(8 ~ 8)	        0	    #ababab
      // 상당한 위험(9 ~ 12)	      4	    #ff8282
      // 중대한 위험(15 ~ 15)	      0	    #ff7a7a
      // 매우 중대한 위험(16 ~ 20)	0	    #9c0000

      this.$set(this.riskStandardChart.chartOptions, 'colors', this.$_.map(this.status.riskStandardChart, 'color'))
      this.$set(this.riskStandardChart.chartOptions, 'labels', this.$_.map(this.status.riskStandardChart, item => {
        return this.$_.split(item.riskContents, '\n')
      }))
      this.$set(this.riskStandardChart, 'series', this.$_.map(this.status.riskStandardChart, 'cnt'))

      this.riskStandardChart.chartWidth = '90%';
      this.$refs['riskStandardChart'].refresh();
    },
    setProcessImprChart() {
      // 공정별 개선대책 수립 및 실시
      // processName	cntIng	cntInaction	cntComplete
      // RM-T3 공정	  0	      0	          0
      // 건조-탈수	  0	      0	          0
      // this.$set(this.processImprChart.chartOptions, 'colors', this.$_.map(this.status.processImprChart, 'color'))
      this.$set(this.processImprChart.chartOptions.xaxis, 'categories', this.$_.map(this.status.processImprChart, 'processName'))
      let series = [
        {
          name: this.$comm.getLangLabel('LBL0001213'), // 진행 건 수
          data: this.$_.map(this.status.processImprChart, 'cntIng'),
        },
        {
          name: this.$comm.getLangLabel('LBL0001214'), // 완료 건 수
          data: this.$_.map(this.status.processImprChart, 'cntComplete'),
        }
      ];
      if (!this.param.vendorFlag) {
        series.splice(1, 0, {
          name: this.$comm.getLangLabel('LBL0001215'), // 지연 건 수
          data: this.$_.map(this.status.processImprChart, 'cntInaction'),
        });
      }
      this.$set(this.processImprChart, 'series', series)

      this.processImprChart.chartWidth = '100%';
      this.$refs['processImprChart'].refresh();
    },
    setProcessRiskRegisterChart() {
      // 공정별 위험등록부
      // processName	cntMan	cntMachine	cntMedia	cntManagement
      // RM-T3 공정		 2		  0			      0			    0
      // 건조-탈수		 0		  1			      0			    0
      // this.$set(this.processRiskRegisterChart.chartOptions, 'colors', this.$_.map(this.status.processRiskRegisterChart, 'color'))

      if (this.param.ramTechniqueCd === 'RT00000001') {
        // HAZOP (o)
        this.$_.forEach(this.status.processRiskRegisterChart, _chart => {
          this.processRiskRegisterChart.series.push({
            name: _chart.processName,
            data: [_chart.cntProcess]
          })
        })
        this.$set(this.processRiskRegisterChart.chartOptions.title, 'text', this.$comm.getLangLabel('LBL0001216')) // 공정별 위험등록부 건 수
      } else if (this.param.ramTechniqueCd === 'RT00000005') {
        // K-PSR (o)
        this.$_.forEach(this.status.processRiskRegisterChart, _chart => {
          this.processRiskRegisterChart.series.push({
            name: _chart.processName,
            data: [_chart.cntProcess]
          })
        })
        this.$set(this.processRiskRegisterChart.chartOptions.title, 'text', this.$comm.getLangLabel('LBL0001216')) // 공정별 위험등록부 건 수
      } else if (this.param.ramTechniqueCd === 'RT00000010') {
        // Check-list (o)
        this.processRiskRegisterChart.series = [
          {
            name: this.$comm.getLangLabel('LBLJOB'), // 작업
            data: [],
          },
          {
            name: this.$comm.getLangLabel('LBL0001220'), // 기계·기구
            data: [],
          },
          {
            name: this.$comm.getLangLabel('LBL0001221'), // 유해인자
            data: [],
          },
          {
            name: this.$comm.getLangLabel('LBL0001222'), // 기타
            data: [],
          },
        ]
        this.$set(this.riskChart.chartOptions.title, 'text', this.$comm.getLangLabel('LBL0001217')) // 평가결과 발굴 현황(%)
        this.$set(this.riskStandardChart.chartOptions.title, 'text', this.$comm.getLangLabel('LBL0001218')) // 평가결과별 발굴 건 수
        this.$set(this.processRiskRegisterChart.chartOptions.title, 'text', this.$comm.getLangLabel('LBL0001219')) // 공정별 평가대상 분류에 따른 위험등록부 건 수
        this.$set(this.processRiskRegisterChart.series[0], 'data', this.$_.map(this.status.processRiskRegisterChart, 'cntJob'))
        this.$set(this.processRiskRegisterChart.series[1], 'data', this.$_.map(this.status.processRiskRegisterChart, 'cntEquip'))
        this.$set(this.processRiskRegisterChart.series[2], 'data', this.$_.map(this.status.processRiskRegisterChart, 'cntHarmful'))
        this.$set(this.processRiskRegisterChart.series[3], 'data', this.$_.map(this.status.processRiskRegisterChart, 'cntEtc'))
      } else if (this.param.ramTechniqueCd === 'RT00000015') {
        // JRA (o)
        this.processRiskRegisterChart.series = [
          {
            name: this.$comm.getLangLabel('LBL0001223'), // 기계(설비)
            data: [],
          },
          {
            name: this.$comm.getLangLabel('LBL0001224'), // 생물학
            data: [],
          },
          {
            name: this.$comm.getLangLabel('LBL0001225'), // 전기적
            data: [],
          },
          {
            name: this.$comm.getLangLabel('LBL0001226'), // 작업특성
            data: [],
          },
          {
            name: this.$comm.getLangLabel('LBL0001227'), // 화학(물질)
            data: [],
          },
          {
            name: this.$comm.getLangLabel('LBL0001228'), // 작업환경
            data: [],
          },
          {
            name: this.$comm.getLangLabel('LBL0001229'), // 관리적
            data: [],
          },
          {
            name: this.$comm.getLangLabel('LBL0001222'), // 기타
            data: [],
          },
        ]
        this.$set(this.processRiskRegisterChart.chartOptions.title, 'text', this.$comm.getLangLabel('LBL0001230')) // 공정별 유해위험요인 분류에 따른 위험등록부 건 수
        this.$set(this.processRiskRegisterChart.series[0], 'data', this.$_.map(this.status.processRiskRegisterChart, 'cntMachine'))
        this.$set(this.processRiskRegisterChart.series[1], 'data', this.$_.map(this.status.processRiskRegisterChart, 'cntBio'))
        this.$set(this.processRiskRegisterChart.series[2], 'data', this.$_.map(this.status.processRiskRegisterChart, 'cntElectronic'))
        this.$set(this.processRiskRegisterChart.series[3], 'data', this.$_.map(this.status.processRiskRegisterChart, 'cntCharacter'))
        this.$set(this.processRiskRegisterChart.series[4], 'data', this.$_.map(this.status.processRiskRegisterChart, 'cntMaterial'))
        this.$set(this.processRiskRegisterChart.series[5], 'data', this.$_.map(this.status.processRiskRegisterChart, 'cntWorkEnv'))
        this.$set(this.processRiskRegisterChart.series[6], 'data', this.$_.map(this.status.processRiskRegisterChart, 'cntManagement'))
        this.$set(this.processRiskRegisterChart.series[7], 'data', this.$_.map(this.status.processRiskRegisterChart, 'cntEtc'))
      } else if (this.param.ramTechniqueCd === 'RT00000020') {
        // KRAS (o)
        this.processRiskRegisterChart.series = [
          {
            name: this.$comm.getLangLabel('LBL0001223'), // 기계(설비)
            data: [],
          },
          {
            name: this.$comm.getLangLabel('LBL0001224'), // 생물학
            data: [],
          },
          {
            name: this.$comm.getLangLabel('LBL0001225'), // 전기적
            data: [],
          },
          {
            name: this.$comm.getLangLabel('LBL0001226'), // 작업특성
            data: [],
          },
          {
            name: this.$comm.getLangLabel('LBL0001227'), // 화학(물질)
            data: [],
          },
          {
            name: this.$comm.getLangLabel('LBL0001228'), // 작업환경
            data: [],
          },
          {
            name: this.$comm.getLangLabel('LBL0001229'), // 관리적
            data: [],
          },
          {
            name: this.$comm.getLangLabel('LBL0001222'), // 기타
            data: [],
          },
        ]
        this.$set(this.processRiskRegisterChart.chartOptions.title, 'text', this.$comm.getLangLabel('LBL0001230')) // 공정별 유해위험요인 분류에 따른 위험등록부 건 수
        this.$set(this.processRiskRegisterChart.series[0], 'data', this.$_.map(this.status.processRiskRegisterChart, 'cntMachine'))
        this.$set(this.processRiskRegisterChart.series[1], 'data', this.$_.map(this.status.processRiskRegisterChart, 'cntBio'))
        this.$set(this.processRiskRegisterChart.series[2], 'data', this.$_.map(this.status.processRiskRegisterChart, 'cntElectronic'))
        this.$set(this.processRiskRegisterChart.series[3], 'data', this.$_.map(this.status.processRiskRegisterChart, 'cntCharacter'))
        this.$set(this.processRiskRegisterChart.series[4], 'data', this.$_.map(this.status.processRiskRegisterChart, 'cntMaterial'))
        this.$set(this.processRiskRegisterChart.series[5], 'data', this.$_.map(this.status.processRiskRegisterChart, 'cntWorkEnv'))
        this.$set(this.processRiskRegisterChart.series[6], 'data', this.$_.map(this.status.processRiskRegisterChart, 'cntManagement'))
        this.$set(this.processRiskRegisterChart.series[7], 'data', this.$_.map(this.status.processRiskRegisterChart, 'cntEtc'))
      } else if (this.param.ramTechniqueCd === 'RT00000025') {
        // 4M (o)
        this.processRiskRegisterChart.series = [
          {
            name: 'Man',
            data: [],
          },
          {
            name: 'Machine',
            data: [],
          },
          {
            name: 'Media',
            data: [],
          },
          {
            name: 'Management',
            data: [],
          },
        ]
        this.$set(this.processRiskRegisterChart.chartOptions.title, 'text', this.$comm.getLangLabel('LBL0001230')) // 공정별 유해위험요인 분류에 따른 위험등록부 건 수
        this.$set(this.processRiskRegisterChart.series[0], 'data', this.$_.map(this.status.processRiskRegisterChart, 'cntMan'))
        this.$set(this.processRiskRegisterChart.series[1], 'data', this.$_.map(this.status.processRiskRegisterChart, 'cntMachine'))
        this.$set(this.processRiskRegisterChart.series[2], 'data', this.$_.map(this.status.processRiskRegisterChart, 'cntMedia'))
        this.$set(this.processRiskRegisterChart.series[3], 'data', this.$_.map(this.status.processRiskRegisterChart, 'cntManagement'))
      } else if (this.param.ramTechniqueCd === 'RT00000030') {
        // CHARM (o)
        this.$_.forEach(this.status.processRiskRegisterChart, _chart => {
          this.processRiskRegisterChart.series.push({
            name: _chart.processName,
            data: [_chart.cntProcess]
          })
        })
        this.$set(this.processRiskRegisterChart.chartOptions.title, 'text', this.$comm.getLangLabel('LBL0001216')) // 공정별 위험등록부 건 수
      } else if (this.param.ramTechniqueCd === 'RT00000035') {
        // 3단계 판단법 (o)
        this.$_.forEach(this.status.processRiskRegisterChart, _chart => {
          this.processRiskRegisterChart.series.push({
            name: _chart.processName,
            data: [_chart.cntProcess]
          })
        })
        this.$set(this.riskChart.chartOptions.title, 'text', this.$comm.getLangLabel('LBL0001231')) // 판단결과 발굴 현황(%)
        this.$set(this.riskStandardChart.chartOptions.title, 'text', this.$comm.getLangLabel('LBL0001232')) // 판단결과별 발굴 건 수
        this.$set(this.processRiskRegisterChart.chartOptions.title, 'text', this.$comm.getLangLabel('LBL0001216')) // 공정별 위험등록부 건 수
      }

      this.$set(this.processRiskRegisterChart.chartOptions.xaxis, 'categories', this.$_.map(this.status.processRiskRegisterChart, 'processName'))
      this.processRiskRegisterChart.chartWidth = '100%';
      this.$refs['processRiskRegisterChart'].refresh();
    },
  }
};
</script>