var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row bg-white" }, [
    _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isFirstGraph,
                expression: "!isFirstGraph",
              },
            ],
            staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6",
          },
          [
            _c("apexchart", {
              ref: "riskChart",
              attrs: {
                height: "400",
                width: _vm.riskChart.chartWidth,
                options: _vm.riskChart.chartOptions,
                series: _vm.riskChart.series,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isSecondGraph,
                expression: "!isSecondGraph",
              },
            ],
            staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6",
          },
          [
            _c("apexchart", {
              ref: "riskStandardChart",
              attrs: {
                height: "400",
                width: _vm.riskStandardChart.chartWidth,
                options: _vm.riskStandardChart.chartOptions,
                series: _vm.riskStandardChart.series,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
          [
            _c("apexchart", {
              ref: "processImprChart",
              attrs: {
                height: "300",
                type: "bar",
                width: _vm.processImprChart.chartWidth,
                options: _vm.processImprChart.chartOptions,
                series: _vm.processImprChart.series,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
          [
            _c("apexchart", {
              ref: "processRiskRegisterChart",
              attrs: {
                height: "300",
                type: "bar",
                width: _vm.processRiskRegisterChart.chartWidth,
                options: _vm.processRiskRegisterChart.chartOptions,
                series: _vm.processRiskRegisterChart.series,
              },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }